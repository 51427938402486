import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Product Lifecycle",
  "description": "The management of materials and parts along the different product lifecycle. ",
  "author": "Alva Xiang",
  "categories": ["scm"],
  "date": "2022-11-16T16:00:00.000Z",
  "featured": true,
  "tags": ["scm"],
  "relatedArticles": ["warehousing", "lifecycle-management", "new-product-introduction-npi"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Product life cycle (PLC), refers to the entire process of a product from its market entry preparation to finally being eliminated from the market. A product lifecycle is driven by changing consumer demand and technological advancements. This is mainly contributed by the changes in the market’s consumption patterns: the products, the consumption levels, structures, also consumer psychology.`}</p>
    <p>{`Products, like us people, also go through a cycle of life which includes the different stages of `}<strong parentName="p">{`development, introduction, growth, maturity,`}</strong>{` and `}<strong parentName="p">{`decline.`}</strong></p>
    <h2 {...{
      "id": "product-lifecycle-stages"
    }}>{`Product Lifecycle Stages`}</h2>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Development`}</strong></li>
    </ol>
    <p>{`The development period involves the time when the idea was first developed and runs until the product is successfully manufactured.`}</p>
    <p>{`During this period, there are no sales of the product, but the company’s investment continues to increase.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Introduction`}</strong></li>
    </ol>
    <p>{`The introduction period starts when the product is finally launched to market.`}</p>
    <p>{`During this period, sales are very slow.`}</p>
    <p>{`The cost of production is high at this stage, driving profits to be lower or even negative in the starting period.`}</p>
    <p>{`However, since you’re working with new technology, there are usually no or very few competitors.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Growth`}</strong></li>
    </ol>
    <p>{`The product then becomes quite well-known.`}</p>
    <p>{`The product’s sales are rapidly growing, which drives the profit to increase too.`}</p>
    <p>{`However, due to the rapid growth of the market and profits, more competitors may start to arise.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Maturity`}</strong></li>
    </ol>
    <p>{`When you reach this stage, the market growth trend has finally slowed down or become saturated. This happens when most of the product has been purchased by their potential buyers.`}</p>
    <p>{`The profit gradually declines after reaching its peak.`}</p>
    <p>{`During this stage, the market competition is fierce, so the company must invest a lot in marketing expenses to maintain its product status.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Decline`}</strong></li>
    </ol>
    <p>{`During this period, the product’s sales significantly declined. The profit is also falling sharply.`}</p>
    <p>{`More and more competitors are entering the market.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.63706563706563%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABbqAAAW6gHljkMQAAADaUlEQVQ4y2VTfUxbVRT/3ffVvkc/3hPKynO0G11giTALRVuwUGjidDrMEgd2baEENDHdyCpmiviQiJu4mBCzLCYm6n8To3PL/EgMbpkzU5RmY4s63IC1z3WEDsciS4z/PXNt3Yye5OSec++5v3PP+d0DAOsAKCjKH7/fRHbhCgzDQPGMahmAewGw09Pf4cLiEqIHj6jB5w971NhYaXXfeImn93VQpWIRRVHsjPZgaPwQt5T79e2bK/ljWV3/KHP16tF8Pn/0xm/5T67lrh3LZPUPlpfzX+i63r94Yw3Y0g1ndAzVfeP4NyDr9/vJ3uExaG+9zy3M/ZTIzF8e+GEmvSedTu/OZjJJPZdNzs1d2j1z5uRzP86ee/GXKwsttIL3Tp5nNvXuJ/8F5AAwNVVuvDw4wBh/3sbt1RWs3rqF3PUlrK2u/F0+1eXiSrUDQATAqaFt+CpWgQ2RN//pGjhBEJhQqA3ayCvMpYvnWcMwmM8/+5T//twFjtrTL7Q4zh4eFGnwz/MZ7us9DWWnm0G+CQOnY6WYicuYjsp3AXmeZ4LBIDRNY0ZHR8lrI8M4OJxiDS9wNmKxzvbXmL70AJeDAKUqnWwSLkZKLGsRFvNRK67Hrfg4WvZ/wBFNY7SxAwSUYcPAqZ71or7LLNmffpzb2l1ne6DLbW2PbbZ7e5tKjkdkuxGXWCNuwmLMiuOxUjQ95QEIA04wCcyjD4fx0r5B5tXkTpIbaMCHz4b42bjDft+TLqk1Wmt/oi/EJ7UE9g08Q8J9IfMjnS7lnV3ljlSPC61bbIWnMaTAMrWUCg/e/VYnvSpgnAHS/RuUnT3V8ubYRhMNokQEfME7pOzvqMRQbL3zUEK1dTdaCoCEAOUeq9kqmwTqJtprzW90rHNoHfeo23ZUl7vbqiS6X1dfx9c3eHmavLKykgu2NNN4RhUIe+AxRS2XGLH4WwC3t1Sq3+6SGxObpO2dNY6uLo9s6a7i/TvahftRQ07sPQGfz8c1+hq5yclJeL1eprn5IQqOUS/QJoNTzMRcqLQgrMnCK4JIZKe7VgAkms3SGgjyro0umtWWSqXIxMQEbZDV6XTyxbGUGEJsbWUEsoncYRimEo56dgCq/0EfL4lm2uGKcLidUxSFvkQNBALs1NQUjXOKoigVr9qLcw6Ju4v3FxL5HUxiXDV8AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/fc8a896640bf8c43f22bbfb745ea8ee1/652f5/product-lifecycle-logo.webp 259w", "/static/fc8a896640bf8c43f22bbfb745ea8ee1/c29d2/product-lifecycle-logo.webp 518w", "/static/fc8a896640bf8c43f22bbfb745ea8ee1/77d2c/product-lifecycle-logo.webp 1035w", "/static/fc8a896640bf8c43f22bbfb745ea8ee1/36bbb/product-lifecycle-logo.webp 1090w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/fc8a896640bf8c43f22bbfb745ea8ee1/a2ead/product-lifecycle-logo.png 259w", "/static/fc8a896640bf8c43f22bbfb745ea8ee1/6b9fd/product-lifecycle-logo.png 518w", "/static/fc8a896640bf8c43f22bbfb745ea8ee1/e3189/product-lifecycle-logo.png 1035w", "/static/fc8a896640bf8c43f22bbfb745ea8ee1/525d3/product-lifecycle-logo.png 1090w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/fc8a896640bf8c43f22bbfb745ea8ee1/e3189/product-lifecycle-logo.png",
            "alt": "product lifecycle logo",
            "title": "product lifecycle logo",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": ""
        }}><strong parentName="a">{`Lets start working on your project`}</strong></a></p>
    </blockquote>
    <h2 {...{
      "id": "material-management-in-product-lifecycle"
    }}>{`Material Management in Product Lifecycle`}</h2>
    <p>{`NexPCB has established our SCM Lifecycle Management strategy. Our strategy mainly focuses on the management of individual product parts during the different product lifecycle stages. This includes the materials, components, and pre-made parts.`}</p>
    <p>{`Like products, materials also have a life cycle. Systematic lifecycle analysis and management should be performed on the materials to be used, to avoid selecting aged materials.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Product development period`}</strong></li>
    </ol>
    <p>{`At this stage, the product BOM should first be analyzed. Scheduling the purchase and delivery of materials should be organized well. Ones that might need longer lead times should be assessed first. Alternatives to such materials could also be researched and prepared.`}</p>
    <p>{`Since the warehouse also stores a variety of materials, it should be first ‘cleaned’ to provide space and resources for other products. Materials that have expired should be eliminated and replaced with new materials to avoid delays in the product launch due to any mismanagement of materials and parts.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Introduction period`}</strong></li>
    </ol>
    <p>{`The new product has entered the market. At this time, the demand for raw materials is not very high, but it’s essential for procurement to be done flexibly and quickly. The management goal of this stage is to first reserve materials with long lead time, shorten the material procurement cycle, and finally shorten the product’s time to market.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Growth period`}</strong></li>
    </ol>
    <p>{`By the time a product reaches the growth stage, its demand has greatly increased. Following that, the demand for raw materials needed for production also rises. Hence, to avoid any material shortage or any additional expenses due to the shortage of materials, proper material procurement should be conducted.`}</p>
    <p>{`The SCM conducted at this stage aims to reserve the materials needed through planned procurement and manage them according to the market supply and demand. This being said, the product profits can grow along with the sales.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Mature period`}</strong></li>
    </ol>
    <p>{`Since the product market is nearly saturated and the product competition is fierce at this stage, the requirements for product cost management are higher. Material cost control comes to aid product competitiveness.`}</p>
    <p>{`To do so, one must first analyze the material supply and demand, then choose the more cost-competitive materials. Proper fund allocation to purchase the parts should be done to reduce the cost of materials and parts to reach the ultimate goal of cost reduction.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Decline period`}</strong></li>
    </ol>
    <p>{`In this final stage of the product lifecycle, the demand for materials is gradually decreasing. The management at this stage focuses on inventory reserve management. On the premise of meeting the demand for the product, it is necessary to avoid excessive storage of materials, but the inventory should still be enough to meet the after-sales demand after the product is discontinued.`}</p>
    <p>{`To conclude, doing proper lifecycle management of materials is essential to improve the competitiveness of products, accelerate the product's market entry, and extend the product's lifecycle.`}</p>
    <p>{`To face the fiercely competitive market, the most effective way for a company to survive is to increase its revenue and reduce costs as much as possible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      